exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-projects-360-js": () => import("./../../../src/pages/projects/360.js" /* webpackChunkName: "component---src-pages-projects-360-js" */),
  "component---src-pages-projects-araltools-js": () => import("./../../../src/pages/projects/araltools.js" /* webpackChunkName: "component---src-pages-projects-araltools-js" */),
  "component---src-pages-projects-ccapp-js": () => import("./../../../src/pages/projects/ccapp.js" /* webpackChunkName: "component---src-pages-projects-ccapp-js" */),
  "component---src-pages-projects-salita-js": () => import("./../../../src/pages/projects/salita.js" /* webpackChunkName: "component---src-pages-projects-salita-js" */)
}

